export default [
  // *===============================================---*
  // *--------- user -------------------------------*
  // *===============================================---*
  {
    path: '/user',
    name: 'users-list',
    component: () => import('@/views/modules/user/users-list/UserList.vue'),
    meta: {
      pageTitle: 'users',
      action: 'read',
      resource: 'user',
      breadcrumb: [{
        text: 'users',
        active: true,
      }],
    },
  },
  {
    path: '/user/:id',
    name: 'users-view',
    component: () => import('@/views/modules/user/users-view/UserView.vue'),
    meta: {
      pageTitle: 'users',
      action: 'read',
      resource: 'user',
      breadcrumb: [{
        text: 'users',
        active: true,
      }],
    },
  },
  {
    path: '/user/:id/edit',
    name: 'users-edit',
    component: () => import('@/views/modules/user/users-edit/UserEdit.vue'),
    meta: {
      pageTitle: 'users',
      action: 'update',
      resource: 'user',
      breadcrumb: [{
        text: 'users',
        active: true,
      }],
    },
  },
  // *===============================================---*
  // *--------- role -------------------------------*
  // *===============================================---*
  {
    path: '/role',
    name: 'roles-list',
    component: () => import('@/views/modules/role/roles-list/RolesList.vue'),
    meta: {
      pageTitle: 'roles',
      action: 'read',
      resource: 'role',
      breadcrumb: [{
        text: 'roles',
        active: true,
      }],
    },
  },
  {
    path: '/role/create',
    name: 'roles-add',
    component: () => import('@/views/modules/role/roles-add/RolesAdd.vue'),
    meta: {
      pageTitle: 'roles',
      action: 'create',
      resource: 'role',
      breadcrumb: [{
        text: 'roles',
        active: true,
      }],
    },
  },
  {
    path: '/role/:id',
    name: 'roles-view',
    component: () => import('@/views/modules/role/roles-view/RolesView.vue'),
    meta: {
      pageTitle: 'roles',
      action: 'view',
      resource: 'role',
      breadcrumb: [{
        text: 'roles',
        active: true,
      }],
    },
  },
  {
    path: '/role/:id/edit',
    name: 'roles-edit',
    component: () => import('@/views/modules/role/roles-edit/RolesEdit.vue'),
    meta: {
      pageTitle: 'roles',
      action: 'update',
      resource: 'role',
      breadcrumb: [{
        text: 'roles',
        active: true,
      }],
    },
  },
  {
    path: '/role/:id/bulk-edit',
    name: 'roles-bulk-edit',
    component: () => import('@/views/modules/role/roles-bulk-edit/RolesBulkEdit.vue'),
    meta: {
      pageTitle: 'roles',
      action: 'update',
      resource: 'role',
      breadcrumb: [{
        text: 'roles',
        active: true,
      }],
    },
  },
  // *===============================================---*
  // *--------- promotion -------------------------------*
  // *===============================================---*
  {
    path: '/promotion',
    name: 'promotions-list',
    component: () => import('@/views/modules/promotion/promotion-list/PromotionList.vue'),
    meta: {
      pageTitle: 'promotions',
      action: 'read',
      resource: 'promotion',
      breadcrumb: [{
        text: 'promotions',
        active: true,
      }],
    },
  },
  {
    path: '/promotion/:id/edit',
    name: 'promotions-edit',
    component: () => import('@/views/modules/promotion/promotion-edit/PromotionEdit.vue'),
    meta: {
      pageTitle: 'promotions',
      action: 'update',
      resource: 'promotion',
      breadcrumb: [{
        text: 'promotions',
        active: true,
      }],
    },
  },
  // *===============================================---*
  // *--------- client -------------------------------*
  // *===============================================---*
  {
    path: '/client',
    name: 'clients-list',
    component: () => import('@/views/modules/client/client-list/ClientList.vue'),
    meta: {
      pageTitle: 'clients',
      action: 'read',
      resource: 'client',
      breadcrumb: [{
        text: 'clients',
        active: true,
      }],
    },
  },
  {
    path: '/client/:id/view',
    name: 'clients-view',
    component: () => import('@/views/modules/client/client-view/ClientView.vue'),
    meta: {
      pageTitle: 'clients',
      action: 'read',
      resource: 'client',
      breadcrumb: [{
        text: 'clients',
        active: true,
      }],
    },
  },
  {
    path: '/client/create',
    name: 'clients-add',
    component: () => import('@/views/modules/client/client-add/ClientAdd.vue'),
    meta: {
      pageTitle: 'clients',
      action: 'create',
      resource: 'client',
      breadcrumb: [{
        text: 'clients',
        active: true,
      }],
    },
  },
  // *===============================================---*
  // *--------- contract -------------------------------*
  // *===============================================---*
  {
    path: '/contract',
    name: 'contracts-list',
    component: () => import('@/views/modules/contract/contract-list/ContractList.vue'),
    meta: {
      pageTitle: 'contracts',
      action: 'read',
      resource: 'contract',
      breadcrumb: [
        {
          text: 'contracts',
          active: true,
        },
      ],
    },
  },
  {
    path: '/contract/:id/view',
    name: 'contracts-view',
    component: () => import('@/views/modules/contract/contract-view/ContractView.vue'),
    meta: {
      pageTitle: 'contracts',
      action: 'read',
      resource: 'contract',
      breadcrumb: [{
        text: 'contracts',
        active: true,
      }],
    },
  },
  {
    path: '/contract/create',
    name: 'contracts-add',
    component: () => import('@/views/modules/contract/contract-add/ContractAdd.vue'),
    meta: {
      pageTitle: 'contracts',
      action: 'create',
      resource: 'contract',
      breadcrumb: [{
        text: 'contracts',
        active: true,
      }],
    },
  },
  {
    path: '/contract/:id/edit',
    name: 'contracts-edit',
    component: () => import('@/views/modules/contract/contract-edit/ContractEdit.vue'),
    meta: {
      pageTitle: 'contracts',
      action: 'update',
      resource: 'contract',
      breadcrumb: [
        {
          text: 'contracts',
          active: true,
        },
      ],
    },
  },
  {
    path: '/contract/:id/evaluation-request/create',
    name: 'contracts-evaluation-request-add',
    component: () => import('@/views/modules/evaluationRequest/evaluationRequest-add/EvaluationRequestFormWizard.vue'),
    meta: {
      pageTitle: 'contracts',
      action: 'create',
      resource: 'evaluationRequest',
      breadcrumb: [
        {
          text: 'evaluationRequests',
          active: true,
        },
      ],
    },
  },
  {
    path: '/contract/cost/:id/evaluation-request/create',
    name: 'contracts-cost-evaluation-request-add',
    component: () => import('@/views/modules/evaluationRequest/evaluationRequest-add/CostEvaluationRequestFormWizard.vue'),
    meta: {
      pageTitle: 'contracts',
      action: 'create',
      resource: 'evaluationRequest',
      breadcrumb: [
        {
          text: 'evaluationRequests',
          active: true,
        },
      ],
    },
  },
  // *===============================================---*
  // *--------- quotation -------------------------------*
  // *===============================================---*
  {
    path: '/quotation',
    name: 'quotations-list',
    component: () => import('@/views/modules/quotation/quotation-list/QuotationList.vue'),
    meta: {
      pageTitle: 'quotations',
      action: 'read',
      resource: 'quotation',
      breadcrumb: [
        {
          text: 'quotations',
          active: true,
        },
      ],
    },
  },
  {
    path: '/quotation/create',
    name: 'quotations-add',
    component: () => import('@/views/modules/quotation/quotation-add/QuotationAdd.vue'),
    meta: {
      pageTitle: 'quotations',
      action: 'create',
      resource: 'quotation',
      breadcrumb: [
        {
          text: 'quotations',
          active: true,
        },
      ],
    },
  },
  {
    path: '/quotation/:id/edit',
    name: 'quotations-edit',
    component: () => import('@/views/modules/quotation/quotation-edit/QuotationEdit.vue'),
    meta: {
      pageTitle: 'quotations',
      action: 'update',
      resource: 'quotation',
      breadcrumb: [
        {
          text: 'quotations',
          active: true,
        },
      ],
    },
  },
  // *===============================================---*
  // *--------- evaluationRequest -------------------------------*
  // *===============================================---*
  {
    path: '/evaluation-request',
    name: 'evaluationRequests-list',
    component: () => import('@/views/modules/evaluationRequest/evaluationRequest-list/EvaluationRequestList.vue'),
    meta: {
      pageTitle: 'evaluationRequests',
      action: 'read',
      resource: 'evaluationRequest',
      breadcrumb: [
        {
          text: 'evaluationRequests',
          active: true,
        },
      ],
    },
  },
  {
    path: '/evaluation-request/create',
    name: 'evaluationRequests-add',
    component: () => import('@/views/modules/evaluationRequest/evaluationRequest-add/EvaluationRequestFormWizard.vue'),
    meta: {
      pageTitle: 'evaluationRequests',
      action: 'create',
      resource: 'evaluationRequest',
      breadcrumb: [
        {
          text: 'evaluationRequests',
          active: true,
        },
      ],
    },
  },
  {
    path: '/evaluation-request/:id/edit',
    name: 'evaluationRequests-edit',
    component: () => import('@/views/modules/evaluationRequest/evaluationRequest-edit/EvaluationRequestFormWizard.vue'),
    meta: {
      pageTitle: 'evaluationRequests',
      action: 'update',
      resource: 'evaluationRequest',
      breadcrumb: [
        {
          text: 'evaluationRequests',
          active: true,
        },
      ],
    },
  },
  {
    path: '/evaluation-request/:id/view',
    name: 'evaluationRequests-view',
    component: () => import('@/views/modules/evaluationRequest/evaluationRequest-view/EvaluationRequestView.vue'),
    meta: {
      pageTitle: 'evaluationRequests',
      action: 'read',
      resource: 'evaluationRequest',
      breadcrumb: [{
        text: 'evaluationRequests',
        active: true,
      }],
    },
  },
  {
    path: '/evaluation',
    name: 'evaluations-list',
    component: () => import('@/views/modules/evaluation/evaluation-list/EvaluationList.vue'),
    meta: {
      pageTitle: 'evaluations',
      action: 'read',
      resource: 'evaluation',
      breadcrumb: [
        {
          text: 'evaluations',
          active: true,
        },
      ],
    },
  },
  {
    path: '/evaluation/search',
    name: 'evaluations-search',
    component: () => import('@/views/modules/evaluation/evaluation-search/EvaluationSearch.vue'),
    meta: {
      pageTitle: 'evaluations',
      action: 'read',
      resource: 'evaluation',
      breadcrumb: [
        {
          text: 'evaluations',
          active: true,
        },
      ],
    },
  },
  {
    path: '/evaluation/:id/view',
    name: 'evaluations-view',
    component: () => import('@/views/modules/evaluation/evaluation-view/EvaluationView.vue'),
    meta: {
      pageTitle: 'evaluations',
      action: 'read',
      resource: 'evaluationRequest',
      breadcrumb: [{
        text: 'evaluations',
        active: true,
      }],
    },
  },
  {
    path: '/evaluation/:id/edit',
    name: 'evaluations-edit',
    component: () => import('@/views/modules/evaluation/evaluation-edit/EvaluationEditFormWizard.vue'),
    meta: {
      pageTitle: 'evaluations',
      action: 'inspect',
      resource: 'evaluation',
      breadcrumb: [{
        text: 'evaluations',
        active: true,
      }],
    },
  },
  {
    path: '/evaluation/:id/review',
    name: 'evaluations-review',
    component: () => import('@/views/modules/evaluation/evaluation-edit/EvaluationEditFormWizard.vue'),
    meta: {
      pageTitle: 'evaluations',
      action: 'review',
      resource: 'evaluation',
      breadcrumb: [{
        text: 'evaluations',
        active: true,
      }],
    },
  },
  {
    path: '/evaluation/create/:id',
    name: 'evaluations-add',
    component: () => import('@/views/modules/evaluation/evaluation-add/EvaluationAddFormWizard.vue'),
    meta: {
      pageTitle: 'evaluations',
      action: 'create',
      resource: 'evaluationRequest',
      breadcrumb: [
        {
          text: 'evaluations',
          active: true,
        },
      ],
    },
  },
  // *===============================================---*
  // *--------- invoice -------------------------------*
  // *===============================================---*
  {
    path: '/invoice',
    name: 'invoices-list',
    component: () => import('@/views/modules/invoice/invoice-list/InvoiceList.vue'),
    meta: {
      pageTitle: 'invoices',
      action: 'read',
      resource: 'invoice',
      breadcrumb: [{
        text: 'invoices',
        active: true,
      }],
    },
  },
  {
    path: '/invoice/:id/edit',
    name: 'invoices-edit',
    component: () => import('@/views/modules/invoice/invoice-edit/InvoiceEdit.vue'),
    meta: {
      pageTitle: 'invoices',
      action: 'update',
      resource: 'invoice',
      breadcrumb: [{
        text: 'invoices',
        active: true,
      }],
    },
  },
  // *===============================================---*
  // *--------- control panel -------------------------------*
  // *===============================================---*
  {
    path: '/control-panel/city',
    name: 'cities-list',
    component: () => import('@/views/modules/control-panel/city/city-list/CityList.vue'),
    meta: {
      pageTitle: 'cities',
      action: 'read',
      resource: 'globalSettings',
      breadcrumb: [
        {
          text: 'cities',
          active: true,
        },
      ],
    },
  },
  {
    path: '/control-panel/district',
    name: 'districts-list',
    component: () => import('@/views/modules/control-panel/district/district-list/DistrictList.vue'),
    meta: {
      pageTitle: 'districts',
      action: 'read',
      resource: 'globalSettings',
      breadcrumb: [
        {
          text: 'districts',
          active: true,
        },
      ],
    },
  },
  {
    path: '/control-panel/area',
    name: 'areas-list',
    component: () => import('@/views/modules/control-panel/area/area-list/AreaList.vue'),
    meta: {
      pageTitle: 'areas',
      action: 'read',
      resource: 'globalSettings',
      breadcrumb: [
        {
          text: 'areas',
          active: true,
        },
      ],
    },
  },
  {
    path: '/control-panel/property-type',
    name: 'property-types-list',
    component: () => import('@/views/modules/control-panel/propertyType/propertyType-list/PropertyTypeList.vue'),
    meta: {
      pageTitle: 'property_types',
      action: 'read',
      resource: 'globalSettings',
      breadcrumb: [
        {
          text: 'property_types',
          active: true,
        },
      ],
    },
  },
  {
    path: '/control-panel/property-type',
    name: 'property-types-edit',
    component: () => import('@/views/modules/control-panel/propertyType/propertyType-edit/PropertyTypeEdit.vue'),
    meta: {
      pageTitle: 'property_types',
      action: 'update',
      resource: 'globalSettings',
      breadcrumb: [
        {
          text: 'property_types',
          active: true,
        },
      ],
    },
  },
  {
    path: '/control-panel/property-classification',
    name: 'property-classifications-list',
    component: () => import('@/views/modules/control-panel/propertyClassification/propertyClassification-list/PropertyClassificationList.vue'),
    meta: {
      pageTitle: 'property_classifications',
      action: 'read',
      resource: 'globalSettings',
      breadcrumb: [
        {
          text: 'property_classifications',
          active: true,
        },
      ],
    },
  },
  {
    path: '/control-panel/property-classification/:id/edit',
    name: 'property-classifications-edit',
    component: () => import('@/views/modules/control-panel/propertyClassification/propertyClassification-edit/PropertyClassificationEdit.vue'),
    meta: {
      pageTitle: 'property_classifications',
      action: 'read',
      resource: 'globalSettings',
      breadcrumb: [{
        text: 'property_classifications',
        active: true,
      }],
    },
  },
  {
    path: '/control-panel/property-feature-group',
    name: 'property-feature-groups-list',
    component: () => import('@/views/modules/control-panel/evaluation/propertyFeatureGroup/propertyFeatureGroup-list/PropertyFeatureGroupList.vue'),
    meta: {
      pageTitle: 'property_feature_groups',
      action: 'read',
      resource: 'globalSettings',
      breadcrumb: [
        {
          text: 'property_feature_groups',
          active: true,
        },
      ],
    },
  },
  {
    path: '/control-panel/property-feature-group/:id',
    name: 'property-feature-groups-edit',
    component: () => import('@/views/modules/control-panel/evaluation/propertyFeatureGroup/propertyFeatureGroup-edit/PropertyFeatureGroupEdit.vue'),
    meta: {
      pageTitle: 'property_feature_groups',
      action: 'read',
      resource: 'globalSettings',
      breadcrumb: [
        {
          text: (route) => route.params.id,
          active: true,
        },
      ],
    },
  },
  {
    path: '/control-panel/property-feature/:id',
    name: 'property-features-list',
    component: () => import('@/views/modules/control-panel/evaluation/propertyFeature/propertyFeature-list/PropertyFeatureList.vue'),
    meta: {
      pageTitle: (route) => route.params.id,
      action: 'read',
      resource: 'globalSettings',
      breadcrumb: [
        {
          text: (route) => route.params.id,
          active: true,
        },
      ],
    },
  },
  {
    path: '/control-panel/property-feature/:feature/:id/edit',
    name: 'property-features-edit',
    component: () => import('@/views/modules/control-panel/evaluation/propertyFeature/propertyFeature-edit/PropertyFeatureEdit.vue'),
    meta: {
      pageTitle: (route) => route.params.feature,
      action: 'read',
      resource: 'globalSettings',
      breadcrumb: [{
        text: (route) => route.params.feature,
        active: true,
      }],
    },
  },
  // *===============================================---*
  // *--------- report -------------------------------*
  // *===============================================---*
  {
    path: '/report',
    name: 'reports-list',
    component: () => import('@/views/modules/report/report-list/ReportList.vue'),
    meta: {
      pageTitle: 'reports',
      action: 'read',
      resource: 'evaluation',
      breadcrumb: [{
        text: 'reports',
        active: true,
      }],
    },
  },
  {
    path: '/report/new',
    name: 'reports-add',
    component: () => import('@/views/modules/report/report-add/ReportAdd.vue'),
    meta: {
      pageTitle: 'reports',
      action: 'read',
      resource: 'evaluation',
      breadcrumb: [{
        text: 'reports',
        active: true,
      }],
    },
  },
  {
    path: '/report/:id/edit',
    name: 'reports-edit',
    component: () => import('@/views/modules/report/report-edit/ReportEdit.vue'),
    meta: {
      pageTitle: 'reports',
      action: 'update',
      resource: 'evaluation',
      breadcrumb: [{
        text: 'reports',
        active: true,
      }],
    },
  },
  // *===============================================---*
  // *--------- report template  -------------------------------*
  // *===============================================---*
  {
    path: '/template',
    name: 'templates-list',
    component: () => import('@/views/modules/template/template-list/TemplateList.vue'),
    meta: {
      pageTitle: 'templates',
      action: 'read',
      resource: 'evaluation',
      breadcrumb: [{
        text: 'templates',
        active: true,
      }],
    },
  },
  {
    path: '/template/new',
    name: 'templates-add',
    component: () => import('@/views/modules/template/template-add/TemplateAdd.vue'),
    meta: {
      pageTitle: 'templates',
      action: 'read',
      resource: 'evaluation',
      breadcrumb: [{
        text: 'templates',
        active: true,
      }],
    },
  },
  {
    path: '/template/:id/edit',
    name: 'templates-edit',
    component: () => import('@/views/modules/template/template-edit/TemplateEdit.vue'),
    meta: {
      pageTitle: 'templates',
      action: 'update',
      resource: 'evaluation',
      breadcrumb: [{
        text: 'templates',
        active: true,
      }],
    },
  },
  // *--------- research-models  -------------------------------*
  // *===============================================---*
  {
    path: '/research-models',
    name: 'research-models-list',
    component: () => import('@/views/modules/research-models/research-models-list/ModelList.vue'),
    meta: {
      pageTitle: 'research-models',
      action: 'read',
      resource: 'evaluation',
      breadcrumb: [{
        text: 'research-models',
        active: true,
      }],
    },
  },
  {
    path: '/research-models/new',
    name: 'research-models-add',
    component: () => import('@/views/modules/research-models/research-models-add/ModelAdd.vue'),
    meta: {
      pageTitle: 'research-models',
      action: 'read',
      resource: 'evaluation',
      breadcrumb: [{
        text: 'research-models',
        active: true,
      }],
    },
  },
  {
    path: '/research-models/:id/edit',
    name: 'research-models-edit',
    component: () => import('@/views/modules/research-models/research-models-edit/ModelEdit.vue'),
    meta: {
      pageTitle: 'research-models',
      action: 'update',
      resource: 'evaluation',
      breadcrumb: [{
        text: 'research-models',
        active: true,
      }],
    },
  },

  // *===============================================---*
  // *--------- page -------------------------------*
  // *===============================================---*
  {
    path: '/page',
    name: 'pages-list',
    component: () => import('@/views/modules/page/page-list/PageList.vue'),
    meta: {
      pageTitle: 'pages',
      action: 'read',
      resource: 'evaluation',
      breadcrumb: [{
        text: 'pages',
        active: true,
      }],
    },
  },
  {
    path: '/page/:id/edit',
    name: 'pages-edit',
    component: () => import('@/views/modules/page/page-edit/PageEdit.vue'),
    meta: {
      pageTitle: 'pages',
      action: 'update',
      resource: 'evaluation',
      breadcrumb: [{
        text: 'pages',
        active: true,
      }],
    },
  },
  // *===============================================---*
  // *--------- Account -------------------------------*
  // *===============================================---*
  {
    path: '/account',
    name: 'account-profile',
    component: () => import('@/views/modules/account/AccountProfile.vue'),
    meta: {
      pageTitle: 'account_profile',
      action: 'read',
      resource: 'user',

    },
  },

];
