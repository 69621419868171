import Vue from 'vue';

// axios
import axios from 'axios';
import { initialAbility } from '@/libs/acl/config';
import useJwt from '@/auth/jwt/useJwt';
import router from '@/router';
import ability from './acl/ability';

const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  // baseURL: 'http:/localhost:8000/',
  baseURL: process.env.NODE_ENV == 'production' ? 'https://api.muqaym.dev/' : 'http://api.app.local/',
  headers: { Accept: 'application/json' },
});

// Add a response interceptor
axiosIns.interceptors.response.use((response) =>
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data
  response,
(error) => {
  console.log(error);
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Do something with response error
  if (error.response.status === 401) {
    // Remove token from localStorage
    localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName);
    localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName);

    // Remove userData from localStorage
    localStorage.removeItem('userData');

    // Reset ability
    ability.update(initialAbility);

    // Redirect to login page
    router.push({ name: 'auth-login' });
  }
  return Promise.reject(error);
});

axiosIns.defaults.withCredentials = true;
Vue.prototype.$http = axiosIns;

export default axiosIns;
