import useJwt from '@/auth/jwt/useJwt';

/**
 * Return if user is logged in
 * This is completely up to you and how you want to store the token in your frontend application
 * e.g. If you are using cookies to store the application please update this function
 */
// eslint-disable-next-line arrow-body-style
export const isUserLoggedIn = () => {
  return localStorage.getItem(useJwt.jwtConfig.storageTokenKeyName);
};

export const getUserData = async () => {
  try {
    const userData = localStorage.getItem('userData');
    if (userData) {
      return JSON.parse(userData);
    }
    const response = await http.get('/api/me');
    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      return false;
    }
    return false;
  }
};

export const setUserData = (userData) => {
  localStorage.setItem('userData', JSON.stringify(userData));
};

/**
 * This function is used for demo purpose route navigation
 * In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 * Please note role field is just for showing purpose it's not used by anything in frontend
 * We are checking role just for ease
 * NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
export const getHomeRouteForLoggedInUser = (userRole) => '/';
// if (userRole === 'admin') return '/'
// if (userRole === 'company_admin') return { name: 'access-control' }
// return { name: 'auth-login' }
