export default [{
  path: '/register',
  name: 'register',
  component: () => import('@/views/auth/register/Register.vue'),
  meta: {
    layout: 'full',
    pageTitle: 'Register',
    resource: 'Auth',
    redirectIfLoggedIn: true,
  },
},
{
  path: '/register/member',
  name: 'register-member',
  component: () => import('@/views/auth/register/RegisterMember.vue'),
  meta: {
    layout: 'full',
    pageTitle: 'Register',
    resource: 'Auth',
    redirectIfLoggedIn: true,
  },
},
{
  path: '/register/company',
  name: 'register-company',
  component: () => import('@/views/auth/register/RegisterCompany.vue'),
  meta: {
    layout: 'full',
    pageTitle: 'Register',
    resource: 'Auth',
    redirectIfLoggedIn: true,
  },
},
{
  path: '/activate-account/:token',
  name: 'activate-account',
  component: () => import('@/views/auth/ActivateAccount.vue'),
  meta: {
    layout: 'full',
    resource: 'Auth',
    redirectIfLoggedIn: true,
  },
},
{
  path: '/login',
  name: 'auth-login',
  component: () => import('@/views/auth/Login.vue'),
  meta: {
    layout: 'full',
    resource: 'Auth',
    redirectIfLoggedIn: true,
  },
},
{
  path: '/forgot-password',
  name: 'auth-forgot-password-v2',
  component: () => import('@/views/auth/ForgotPassword.vue'),
  meta: {
    layout: 'full',
    resource: 'Auth',
    redirectIfLoggedIn: true,
  },
},
{
  path: '/reset-password',
  name: 'auth-reset-password-v1',
  component: () => import('@/views/auth/ResetPassword.vue'),
  meta: {
    layout: 'full',
    resource: 'Auth',
    redirectIfLoggedIn: true,
  },
},
];
